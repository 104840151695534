<template>
    <div :class="classes">
        <label
            v-if="shouldShowLabel"
            :for="labelFor"
        >{{ labelValue }}</label>

        <slot>
            <!-- Input -->
            <y-form-input
                v-if="ofType === 'text'"
                :id="labelFor"
                v-model="model"
                v-validate="getValidation()"
                :type="componentType"
                :placeholder="getValue('placeholder')"
                :name="getValue('name')"
                :readonly="getBoolValue('readonly')"
                :disabled="getBoolValue('disabled')"
                :min="getValue('min')"
                :max="getValue('max')"
                :step="getValue('step')"
                :data-vv-as="labelValue"
                :data-vv-name="labelFor"
                :scope="scope"
                :dir="dir"
                @blur="$emit('blur', $event)"
            />

            <!-- Number -->
            <y-form-number
                v-if="ofType === 'number' || ofType === 'mobile'"
                :id="labelFor"
                v-model="model"
                v-validate="getValidation()"
                :placeholder="getValue('placeholder')"
                :name="getValue('name')"
                :readonly="getBoolValue('readonly')"
                :disabled="getBoolValue('disabled')"
                :locale="getValue('locale')"
                :format="getValue('format', 'number')"
                :data-vv-as="labelValue"
                :data-vv-name="labelFor"
                :scope="scope"
                :dir="dir"
                @blur="$emit('blur', $event)"
            />

            <!-- Textarea -->
            <y-form-textarea
                v-else-if="ofType === 'textarea'"
                :id="labelFor"
                v-model="model"
                v-validate="getValidation()"
                :placeholder="getValue('placeholder')"
                :name="getValue('name')"
                :readonly="getBoolValue('readonly')"
                :disabled="getBoolValue('disabled')"
                :rows="getValue('rows')"
                :cols="getValue('cols')"
                :character-limit="getValue('char_limit')"
                :no-resize="getBoolValue('no_resize')"
                :data-vv-as="labelValue"
                :data-vv-name="labelFor"
                :scope="scope"
                :dir="dir"
            />

            <!-- Array inputs -->
            <y-form-array
                v-else-if="ofType === 'array'"
                :id="labelFor"
                v-model="model"
                v-validate="getValidation()"
                :placeholder="getValue('placeholder')"
                :name="getValue('name')"
                :readonly="getBoolValue('readonly')"
                :disabled="getBoolValue('disabled')"
                :rows="getValue('rows')"
                :cols="getValue('cols')"
                :no-resize="getBoolValue('no_resize')"
                :data-vv-as="labelValue"
                :data-vv-name="labelFor"
                :scope="scope"
                :dir="dir"
            />

            <!-- Checkbox -->
            <y-form-checkbox
                v-else-if="ofType === 'checkbox'"
                :id="labelFor"
                v-model="model"
                v-validate="getValidation()"
                :name="getValue('name')"
                :label="getValue('label') || labelValue"
                :true-val="getValue('true', 1)"
                :false-val="getValue('false', 0)"
                :disabled="getBoolValue('disabled')"
                :no-label="getBoolValue('no_label')"
                :alt="getBoolValue('alt')"
                :inline="getBoolValue('inline')"
                :color="getValue('color')"
                :size="getValue('size')"
                :switch="getBoolValue('switch')"
                :circular="getBoolValue('circular')"
                :endpoint="getValue('endpoint')"
                :data-vv-as="labelValue"
                :data-vv-name="labelFor"
                :scope="scope"
                @change="$emit('change')"
            />


            <!-- Checkbox List -->
            <y-form-checkbox-list
                v-else-if="ofType === 'checkbox-list'"
                :id="labelFor"
                v-model="model"
                v-validate="getValidation()"
                :name="getValue('name')"
                :options="getValue('options')"
                :disabled="getBoolValue('disabled')"
                :no-label="getBoolValue('no_label')"
                :alt="getBoolValue('alt')"
                :inline="getBoolValue('inline')"
                :color="getValue('color')"
                :size="getValue('size')"
                :switch="getBoolValue('switch')"
                :circular="getBoolValue('circular')"
                :data-vv-as="labelValue"
                :data-vv-name="labelFor"
                :scope="scope"
            />


            <!-- Radio -->
            <y-form-radio
                v-else-if="ofType === 'radio'"
                :id="labelFor"
                v-model="model"
                v-validate="getValidation()"
                :name="getValue('name')"
                :options="getValue('options')"
                :disabled="getBoolValue('disabled')"
                :no-label="getBoolValue('no_label')"
                :alt="getBoolValue('alt')"
                :inline="getBoolValue('inline')"
                :color="getValue('color')"
                :size="getValue('size')"
                :switch="getBoolValue('switch')"
                :circular="getBoolValue('circular')"
                :data-vv-as="labelValue"
                :data-vv-name="labelFor"
                :scope="scope"
            />


            <!-- Select -->
            <y-form-select
                v-else-if="ofType === 'select'"
                :id="labelFor"
                v-model="model"
                v-validate="getValidation()"
                :name="getValue('name')"
                :options="getValue('options')"
                :placeholder="getValue('placeholder')"
                :endpoint="getValue('endpoint')"
                :search="getValue('search')"
                :group-values="getValue('group_values')"
                :group-label="getValue('group_label')"
                :group-select="getBoolValue('group_select')"
                :value-field="getValue('value_field') || get('value_field', 'value')"
                :label-field="getValue('label_field') || get('label_field', 'label')"
                :is-loading="getBoolValue('is_loading')"
                :multi="getBoolValue('multi')"
                :taggable="getBoolValue('taggable')"
                :allow-empty="getBoolValue('allow_empty')"
                :disabled="getBoolValue('disabled')"
                :close-on-select="getBoolValue('close_on_select')"
                :data-vv-as="labelValue"
                :data-vv-name="labelFor"
                :scope="scope"
                :add-including="addIncluding"
                @select="$emit('select', $event)"
                @tag="$emit('tag', $event)"
            />

            <!-- Checkbox List -->
            <y-form-color-picker
                v-else-if="ofType === 'color-picker'"
                :id="labelFor"
                v-model="model"
                v-validate="getValidation()"
                :name="getValue('name')"
                :disabled="getBoolValue('disabled')"
                :no-label="getBoolValue('no_label')"
                :palette="getValue('palette', null)"
                :only-palette="getBoolValue('only_palette')"
                :data-vv-as="labelValue"
                :data-vv-name="labelFor"
                :scope="scope"
            />
        </slot>

        <div
            v-if="getValue('help')"
            class="help"
        >
            {{ getValue('help') }}
        </div>
        <div
            v-show="hasError"
            class="help color-red"
        >
            {{ error | digits }}
        </div>
    </div>
</template>

<script>

    import { YFormInput } from '@deps/form/elements/Input';
    import { YFormNumber } from '@deps/form/elements/Number';
    import { YFormTextarea } from '@deps/form/elements/Textarea';
    import { YFormArray } from '@deps/form/elements/Array';
    import { YFormCheckbox } from '@deps/form/elements/Checkbox';
    import { YFormCheckboxList } from '@deps/form/elements/CheckboxList';
    import { YFormColorPicker } from '@deps/form/elements/ColorPicker';
    import { YFormRadio } from '@deps/form/elements/Radio';
    import { YFormSelect } from '@deps/form/elements/Select';

    import { snakeToCamel, generateId } from '@nodes/helpers/string';

    /**
     * Form Field
     */
    export default {

        name: 'FormField',

        components: {
            YFormInput,
            YFormNumber,
            YFormTextarea,
            YFormArray,
            YFormCheckbox,
            YFormCheckboxList,
            YFormColorPicker,
            YFormRadio,
            YFormSelect,
        },

        inject: {
            $validator: '$validator',
        },

        props: {

            /**
             * Form Field Params
             */
            params: Object,

            /**
             * Field Value
             */
            value: { // eslint-disable-line vue/require-prop-types
                default: null,
            },

            /**
             * Field Name
             */
            name: {
                type   : String,
                default: null,
            },

            /**
             * Field id
             */
            id: {
                type   : String,
                default: null,
            },

            /**
             * Field Label
             */
            label: String,

            /**
             * Field color
             */
            color: String,

            /**
             * Is field required
             */
            required: Boolean,

            /**
             * Input locale to parse
             * _(Input Number)_
             */
            locale: {
                type: String,
            },

            /**
             * Minimum value
             */
            min: [String, Number, Boolean],

            /**
             * Maximum value
             */
            max: [String, Number, Boolean],

            /**
             * Step of changing value
             */
            step: [String, Number, Boolean],

            /**
             * Is alt
             */
            alt: Boolean,

            /**
             * Is block
             */
            block: Boolean,

            /**
             * Has no label
             */
            noLabel: Boolean,

            /**
             * Make uploader small version
             */
            small: Boolean,

            /**
             * Disable Grammerly
             */
            disableGrammerly: Boolean,

            /**
             * is Loading
             */
            isLoading: Boolean,

            /**
             * Field size
             */
            size: String,

            /**
             * Format
             */
            format: String,

            /**
             * Input type
             */
            type: {
                type   : String,
                default: 'text',
            },

            /**
             * An alias for type
             */
            componentName: {
                type: String,
            },

            /**
             * Validation
             */
            validation: {}, // eslint-disable-line vue/require-prop-types

            /**
             * Checkbox value
             */
            checkboxValue: null,

            /**
             * Is Checkbox inline
             */
            inline: Boolean,

            /**
             * Is circular
             */
            circular: Boolean,

            /**
             * Is Checkbox switch
             */
            switch: Boolean,

            /**
             * Select options
             */
            options: [Array, Object],

            /**
             * Is readonly
             */
            readonly: {
                type   : [Boolean, Number, String],
                default: false,
            },

            /**
             * Field Placeholder
             */
            placeholder: {
                type: String,
            },

            /**
             * Is disabled
             */
            disabled: {
                type   : [Boolean, Number, String],
                default: false,
            },

            /**
             * Textarea rows
             */
            rows: {
                type   : [Number, String],
                default: '5',
            },

            /**
             * Select multiple option
             */
            multi: Boolean,

            /**
             * Select taggable option
             */
            taggable: Boolean,

            /**
             * Should select close after click
             */
            closeOnSelect: Boolean,

            /**
             * Select endpoint options
             */
            endpoint: [Object, Array],

            /**
             * Endpoint search field
             */
            search: String,

            /**
             * Options List Value Field Name
             */
            valueField: {
                type   : String,
                default: 'value',
            },

            /**
             * Options List Label Field Name
             */
            labelField: {
                type   : String,
                default: 'label',
            },

            /**
             * Select can be empty
             */
            allowEmpty: {
                type   : Boolean,
                default: false,
            },

            /**
             * no resizing for textarea
             */
            noResize: Boolean,

            /**
             * Help text
             */
            help: String,

            /**
             * Validation scope
             */
            scope: String,

            /**
             * Make input direction rtl, ltr, or auto
             */
            dir: {
                type   : String,
                default: 'auto',
            },

            /**
             * Character Limit
             */
            charLimit: [Number, String],

            /**
             * Add including
             */
            addIncluding: {
                type   : Boolean,
                default: false,
            },

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model    : this.value,
                el       : null,
                keyHelper: generateId(),
            };
        },

        computed: {

            /**
             * return classes string
             *
             * @returns {Array}
             */
            classes() {
                const classes = [];

                if (this.componentType === 'hidden') {
                    classes.push('d-n');
                }
                if (!this.noWrapper) {
                    classes.push('field');
                }
                if (this.hasError) {
                    classes.push('error');
                }
                if (this.size) {
                    classes.push(this.size);
                }
                if (this.getBoolValue('ltr')) {
                    classes.push('input-ltr');
                }
                if (this.has(this.params, 'css_class')) {
                    classes.push(this.params.css_class);
                }

                if (this.getValue('validation', '').includes('required') || this.getValue('required')) {
                    classes.push('required-label');
                }

                return classes;
            },

            /**
             * Check if label should be shown
             *
             * @returns {*}
             */
            shouldShowLabel() {
                return this.supportLabel
                    && (this.getValue('label') || this.labelValue)
                    && !this.noLabel
                    && !this.get(this.params, 'no_label', false)
                    && this.labelFor !== null
                    && this.isNot('checkbox');
            },

            /**
             * Return first error for this element
             *
             * @returns {*}
             */
            error() {
                return this.errors.first(this.errorField);
            },

            /**
             * Check if it's required
             *
             * @returns {*}
             */
            isRequired() {
                return this.getBoolValue('required');
            },

            /**
             * Return component type
             */
            componentType() {
                return this.params ? this.getValue('component_name') : this.componentName || this.type;
            },

            /**
             * Check is one of registered types
             *
             * @returns {string|null}
             */
            ofType() {
                const generalTypes = [
                    'checkbox', 'checkbox-list', 'radio', 'number', 'array',
                    'textarea', 'select', 'heading', 'divider', 'division', 'editor',
                    'html', 'paragraph', 'color-picker', 'uploader', 'mobile',
                ];

                if (generalTypes.includes(this.componentType)) {
                    return this.componentType;
                }

                if (['text', 'search', 'email', 'url', 'hidden', 'password'].includes(this.componentType)) {
                    return 'text';
                }

                if (['date', 'time', 'datetime'].includes(this.componentType)) {
                    return 'date-picker';
                }

                return null;
            },

            /**
             * Return label target name
             *
             * @returns {*|null}
             */
            labelFor() {
                return this.id || `${this.getValue('name')}-${this.keyHelper}` || this.keyHelper;
            },

            /**
             * Return label value
             *
             * @returns {*|null}
             */
            labelValue() {
                return this.getValue('label')
                    || this.el
                    || this.$t(`fields.${this.getValue('name')}`);
            },

            /**
             * Error field name with scope
             */
            errorField() {
                return this.scope ? `${this.scope}.${this.labelFor}` : this.labelFor;
            },

            /**
             * Check if this element has error
             *
             * @returns {*}
             */
            hasError() {
                return this.errors.has(this.errorField);
            },

            /**
             * Check if this element supports label
             *
             * @returns {boolean}
             */
            supportLabel() {
                const arr = [
                    'text', 'number', 'email', 'password', 'array',
                    'checkbox', 'radio', 'textarea', 'select',
                    'date', 'time', 'datetime',
                    'editor', 'uploader', 'color-picker', 'mobile',
                ];

                return arr.includes(this.componentType);
            },

        },

        watch: {

            /**
             * Watch value set new model
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler(val) {
                    this.$set(this, 'model', val);
                },
                deep: true,
            },

            /**
             * Watch model and emit
             */
            model() {
                this.$emit('input', this.model);
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            if ((this.model === null || this.model === undefined) && this.params) {
                this.model = this.params.value || this.params.default || null;
            }
        },

        /**
         * @inheritDoc
         */
        mounted() {
            const el = this.$el;

            if (el instanceof Element) {
                if (el.hasAttribute('data-vv-as')) {
                    this.el = el.getAttribute('data-vv-as');
                } else if (this.name !== null) {
                    el.setAttribute('data-vv-as', this.$t(`fields.${this.name}`));
                }
            }
        },

        methods: {

            /**
             * Get field value
             *
             * @param value
             * @param defaultValue
             * @returns {*}
             */
            getValue(value, defaultValue = null) {
                if (this.params) {
                    return this.params[value] || defaultValue;
                }
                return this[snakeToCamel(value)] || defaultValue;
            },

            /**
             * Get field value as boolean
             *
             * @param value
             * @param default_value
             * @returns {boolean}
             */
            getBoolValue(value, default_value = false) {
                return !!this.getValue(value, default_value);
            },

            /**
             * Get validation
             */
            getValidation() {
                const validations = this.getValue('validation', '').split('|');
                const results = validations.filter((item) => {
                    const rule = item.match(/^([^:]+)/);
                    return rule ? this.$config('validations.rules').includes(rule[1]) : false;
                });
                return results.join('|');
            },

            /**
             * Emit input event on update text
             *
             * @param event
             */
            updateText(event) {
                this.$emit('input', event.target.value);
            },

            /**
             * Emit input event on checkbox change
             *
             * @param event
             */
            updateChecked(event) {
                this.isChecked = event.target.checked ? 1 : 0;
                this.$emit('input', this.isChecked);
            },

            /**
             * Check type
             *
             * @param type
             * @returns {boolean}
             */
            is(type) {
                return this.ofType === type;
            },

            /**
             * Check type is not
             *
             * @param type
             * @returns {boolean}
             */
            isNot(type) {
                return this.ofType !== type;
            },

            /**
             * Check option in select is checked
             *
             * @param valueA
             * @param valueB
             * @returns {boolean}
             */
            optionIsChecked(valueA, valueB) {
                if (valueA) {
                    valueA = valueA.toString(); // eslint-disable-line
                }

                return valueA === valueB;
            },
        },

    };
</script>
