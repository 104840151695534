/*-----------------------------------------------------------------
- ICIB Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * ICIB Plans Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    plansGrid: (...args) => Resource.get('icib-plans-grid', args),
    
    /**
     * ICIB Organizations Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    organizationsGrid: (...args) => Resource.get('icib-organizations-grid', args),

    /**
     * ICIB Organization Profile
     *
     * @param {...any} args
     * @returns {*}
     */
    getOrganization: (...args) => Resource.get('endpoint-model-single', args),
    
    /**
     * ICIB Invoices Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    invoicesGrid: (...args) => Resource.get('icib-invoices-grid', args),
    
    /**
     * ICIB Invoice Single
     *
     * @param {...any} args
     * @returns {*}
     */
    invoiceFetch: (...args) => Resource.get('icib-invoice-fetch', args),
    
    /**
     * ICIB User Followings Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    userFollowingsGrid: (...args) => Resource.get('icib-user-followings-grid', args),
    
    /**
     * ICIB Organization Followers Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    organizationFollowersGrid: (...args) => Resource.get('icib-organization-followers-grid', args),
    
    /**
     * ICIB Organization Verify Timeline
     *
     * @param {...any} args
     * @returns {*}
     */
    organizationVerifyTimeline: (...args) => Resource.get('icib-organization-get-status-timeline', args),
    
    /**
     * ICIB Achievements Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    achievementsGrid: (...args) => Resource.get('icib-achievements-grid', args),
    
    /**
     * ICIB Organization Admin Details
     *
     * @param {...any} args
     * @returns {*}
     */
    organizationAdminDetails: (...args) => Resource.get('icib-organization-admin-details', args),
    
    /**
     * ICIB Organization Admin Details
     *
     * @param {...any} args
     * @returns {*}
     */
    invoiceDiscountSave: (...args) => Resource.post('icib-invoice-discount-save', args),


    /**
     * ICIB hscode Search
     *
     * @param {...any} args
     * @returns {*}
     */
    hscodeSearch: (...args) => Resource.get('icib-hscode-search', args),

};
