/*-----------------------------------------------------------------
- Fields
-----------------------------------------------------------------*/
export default {
    
    tax_enabled            : 'Tax system should be active.',
    tax_should_add_to_price: 'Tax should be added to the amount, on checkout.',
    tax_seller_id          : 'Tax Seller ID',
    tax_specific_cats      : 'Tax-Free Categories',
    
    original_price: 'Price',
    sale_price    : 'Sale Price',
    price         : {
        with_tax   : 'Price including tax',
        without_tax: 'Price excluding taxes',
    },

    
    sale_count         : 'Sale Count',
    form_original_price: 'Original Price ({0})',
    form_sale_price    : 'Sale Price ({0})',
    sale_price_expire  : 'Sale Price Expire Date',
    expires_at         : 'Expire Date',
    ware_count         : 'Product Item Count',
    wares              : 'Product Items',
    product            : 'Product',
    ware               : 'Product Item',
    is_available       : 'Available',
    labels             : 'Labels',
    product_select     : 'Select Product',
    
    height: 'Height ({0})',
    length: 'Length ({0})',
    width : 'Width ({0})',
    weight: 'Weight ({0})',
    
    unit             : 'Unit',
    market_code      : 'Product Item Code',
    tags             : 'Tags',
    ware_is_available: 'This Product Item is available.',
    approximate_size : 'Approximate Size',
    breakable        : 'Breakable',
    
    supply_unit              : 'Supply unit',
    supply_type              : 'Supply type',
    inventory_count          : 'Inventory count',
    inventory_price          : 'Inventory unit price',
    inventory_supply_price   : 'Inventory Supply Price',
    inventory_return_price   : 'Inventory Return Price',
    inventory_damage_price   : 'Inventory Recovery Price',
    inventory_min_count      : 'Minimum number of inventory',
    inventory_low_level_alarm: 'Announce inventory reduction',
    inventory_low_level_stop : 'Out of reach( if not available )',
    inventory_payment_path   : 'Payment Path',
    
    activate_tax  : 'Independent of Sales Rules',
    tax           : 'Tax',
    tax_type      : 'Tax Type',
    payment_type  : 'Payment Type',
    payment_period: 'Payment Period',
    discount      : 'Discount',
    
    tax_value_added : 'Value-Added Tax (٪)',
    tax_withholding : 'Withholding Taxes (٪)',
    tax_municipality: 'Municipal Charges (٪)',
    
    kind            : 'Change Type',
    kind_inventory  : 'Change Reason',
    alter           : 'Number of Changes',
    effected_at     : 'Effective Date',
    description     : 'Description',
    reason          : 'Reason',
    reason_inventory: 'Damage Reason',
    
    campaigns: {
        amount: {
            radio         : 'Discount Amount',
            percent_number: 'Percent',
        },
    },
    tagging_search: 'Tagging search',
};
